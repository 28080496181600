<template>
	<div class="bg-neutral-50 h-full flex items-center">
		<div class="wrapper">
			<div
				class="left-container"
				:style="{ 'background-image': `url(${props.backgroundCover})` }"
			></div>
			<div class="right-container">
				<slot name="right-container" />
			</div>
		</div>
	</div>
</template>

<script lang="ts" setup>
interface SignInContainerProps {
	backgroundCover: string;
}

const props = defineProps<SignInContainerProps>();
</script>

<style lang="scss" scoped>
.wrapper {
	@apply grid grid-flow-col auto-cols-fr w-full max-w-[1280px] my-[80px] ml-auto mr-auto rounded-[16px] overflow-hidden;
	@apply tablet:my-0 tablet:rounded-none tablet:h-full;
}

.left-container {
	@apply w-full max-w-[640px] bg-cover bg-center;
	@apply mobile:hidden;
}

.right-container {
	@apply w-full max-w-[640px] text-center  bg-[#ffffff];
	@apply mobile:mx-auto mobile:px-[15px];
}
</style>
